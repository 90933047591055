import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import ServerSideExportMenu from "../ServerSideExportCommon/ServerSideExportMenu/ServerSideExportMenu";
import "./LOSExportModal.scss";
import defaultContent from "../../../content/serverSideExport";

type Props = {
  open: boolean;
  onCloseCallbackFn?: Function;
};

export const LOSExportModal = (props: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const { open, onCloseCallbackFn } = props;

  const options = [
    {
      id: "1",
      text: `${t("exportTypeModal.menu.los.all", defaultContent["exportTypeModal"]["menu"]["los"]["all"])}`,
    },
    {
      id: "2",
      text: `${t(
        "exportTypeModal.menu.los.selectedKPIs",
        defaultContent["exportTypeModal"]["menu"]["los"]["selectedKPIs"],
      )}`,
    },
    {
      id: "3",
      text: `${t(
        "exportTypeModal.menu.los.currentView",
        defaultContent["exportTypeModal"]["menu"]["los"]["currentView"],
      )}`,
    },
  ];

  const viewExportedDataButtonOnClick = () => {};

  const viewAllExportedDataButtonOnClick = () => {};

  return (
    <div className="los-export-modal">
      <LeafModal open={open} onCloseCallbackFn={onCloseCallbackFn}>
        <ServerSideExportMenu
          title={`${t("exportTypeModal.header", defaultContent["exportTypeModal"]["header"])}`}
          subTitle={`${t("exportTypeModal.subheader", defaultContent["exportTypeModal"]["subheader"])}`}
          options={options}
          exportButton={{
            text: `${t("exportTypeModal.exportButton", defaultContent["exportTypeModal"]["exportButton"])}`,
            onClick: viewExportedDataButtonOnClick,
          }}
          show={true}
          showExportButton={true}
          showViewExportsButton={true} // to be updated based on data availability
          viewExportsButton={{
            text: `${t("exportTypeModal.viewExportButton", defaultContent["exportTypeModal"]["viewExportButton"])}`,
            onClick: viewAllExportedDataButtonOnClick,
          }}
        />
      </LeafModal>
    </div>
  );
};
