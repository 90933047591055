import "./LeafFilterButton.scss";

type Props = {
  text: string;
  theme?: string;
  active?: boolean;
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: any;
};

const LeafFilterButton = (props: Props) => {
  const { text, onButtonClick, theme, children, active = true } = props;

  return (
    <button
      className={`leaf-filter-button${theme ? " " + theme : ""} leaf-filter-button${active ? "" : "--not-active"}`}
      onClick={onButtonClick}
    >
      {text}
      {children}
    </button>
  );
};

export default LeafFilterButton;
