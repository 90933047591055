const content: { [key: string]: any } = {
  bronzefoundation: "Bronze Foundation+",
  pathtobronze: "Path to Bronze+",
  bronzebuilder: "Bronze Builder+",
  eligibility: "Eligibility",
  ptbLabel: "Path to Bronze Incentive+",
  bfiLabel: "Bronze Foundation Incentive+",
  bbiLabel: "Bronze Builder Incentive+",
  pageNavigation: "Bronze Incentives Page Navigation",
  bronzeToastMessage: {
    BFI: "Your Bronze Foundation+ eligibility has restarted. You can earn an additional 12 payments within a new {noOfMonths} month window. The restarted new window begins the first month you earn the Bronze Foundation Incentive+.",
    BBI: "Your Bronze Builder+ eligibility has restarted. You can earn an additional 12 payments within a new {noOfMonths} month window. The restarted new window begins the first month you earn the Bronze Builder Incentive+.",
  },
  newPYBannerMessage:
    "If you are experiencing inaccuracies with the new PY25 Bronze Incentive eligibility, please know that we are aware and are working to resolve this issue. You can expect this issue to be resolved by September 10th at the latest. Thank you for your understanding and patience.",
  newAboActivation: {
    monthlyReqHeader: "What are the monthly requirements?",
    bonusCalcHeader: "How is the bonus calculated?",
    eligibleModal: {
      whoIsEligibleHeader: "Who is eligible for the Path to Bronze bonus?",
      whoIsEligibleDescription:
        "Eligibility is determined at the end of each performance year. The Path to Bronze Incentive+ is for ABOs below a 9% performance bonus level achievement in the previous performance year. The Amway sales plan offers other bonuses to reward ABOs beyond the 9% performance bonus.",
      notEarningHeader: "You’re eligible to start earning the Path to Bronze Incentive+",
      earningHeader: "You have earned <span>{x} out of 6 times</span> in PY{y}",
      metHeader: "Your Path to Bronze Incentive+ eligibility has been met",
      eligibleDescription: "You can receive a maximum of {max} payments within a performance year.",
      closedYearHeader: "Your Path to Bronze Incentive+ eligibility period has ended for PY{YY}.",
      extended: {
        earningHeader: "You have earned <span>{x} out of 6 times</span> in your 18 month window.",
        eligibleDescription: "You can receive a maximum of {max} payments within an 18 month window.",
      },
    },
    performanceBonusReq: {
      title: "{perfBonus}% Performance Bonus",
    },
    ppvReq: {
      title: "{targetPPV} {volumeType}",
    },
    pvReq: {
      tag: "Additional <strong>{neededPV} {volumeType} needed</strong>",
      tagMet: "You've met this requirement.",
      progressSubtitle: "Target: {targetPV}",
    },
    eligibilityTile: {
      title: "Check your eligibility",
      description: "You’re eligible to earn this incentive ",
      descriptionRemaining: "{remainingTimes} more times ",
      descriptionPeriod: "until {monthYYYY}.",
      ineligibleTitle: "You are ineligible for the Path to Bronze Incentive+ in {dateText}",
      graduatedTitle: "You have completed your eligibility for Path to Bronze+",
      ineligibleDescription: "If you require more information, please contact Customer Support for further details.",
      ineligibleForPYDescription:
        "The Path to Bronze Incentive+ is for ABOs below a 9% performance bonus level achievement in the previous performance year. The Amway sales plan offers other bonuses to reward ABOs beyond the 9% performance bonus.",
      viewEligibility: "View Eligibility",
    },
    allReqsMet: "Great job! You’re earning the Path to Bronze Incentive+",
    earningIncentiveTitle: "Great job! Keep going to level up your incentive",
    notEarningIncentiveTitle: "You're not earning the Path to Bronze Incentive+",
    manuallyApprovedIncentiveTitle: "You've been manually approved for the Path to Bronze Incentive+",
    deniedIncentiveTitle: "{month} business is not eligible for the Path to Bronze Incentive+",
    onHoldIncentiveTitle: "Your Path to Bronze Incentive+ award is on hold",
    customerCareContactDescription: "For more information, please contact Amway Customer Care.",
    downlineGrowthDescription: "The Path to Bronze Incentive+ rewards new downline growth.",
    bonusAmountDescription: "You can earn up to <1>{amount}</1> with this bonus!",
    totalsAsOf: "Totals as of {timestamp}",
    learnMore: "Learn More",
  },
  calcDescTile: {
    twoLegs:
      "If both base requirements are met within a single month, a {firstBonusAmount} payout is achieved. Level up by exceeding the new legs at 3% requirement for a {secondBonusAmount} payout.",
    threeLegs:
      "If both base requirements are met within a single month, a {firstBonusAmount} incentive is achieved. Level up through an additional one or two new legs at {volumeNeeded} {volumeType} requirement for respectively {secondBonusAmount} or {thirdBonusAmount} incentive.",
  },
  legRequirementTile: {
    title: "{firstLegTaget} New Legs at {volumeNeeded} {volumeType}",
    descriptionOne: "Legs are considered to be new within <1>{numMonths} months</1> from registration date.",
    descriptionTwo: "Additionally level up your incentive payment when you achieve ",
    newLegs: "3 new legs",
    gpvNeeded: " with {gpvNeeded} {volumeType}.",
    additional: "Additional ",
    tagSingular: "Additional <1>{legsNeeded} leg needed</1>",
    tagPlural: "Additional <1>{legsNeeded} legs needed</1>",
    tagMetRequirement: "You’ve met this requirement.",
    additionalLegNeeded: "Additional 1 leg needed",
    earnedLegReqSingular: "{bonusAmount} earned for {legTarget} new leg",
    earnedLegReqPlural: "{bonusAmount} earned for {legTarget} new legs",
  },
  trackingRequirementTile: {
    title: "Track your progress",
    inProgressLabelStart: "You’ve met ",
    inProgressLabelMiddle: "{x} of {y} ",
    inProgressLabelEnd: "requirements to earn this incentive.",
    inProgressLabelEndAdditionalLeg:
      "requirements to earn this incentive and added an additional leg with {volumeRequirement} {volumeType}.",
    inProgressLabelEndAdditionalLegs:
      "requirements to earn this incentive and added an additional 2 legs with {volumeRequirement} {volumeType}.",
    metlabel: "You have maximized the amount of incentive you can reach.",
    requirementLabelStart: "Once all requirements are met, there is opportunity to ",
    requirementLabelMiddle: "level up ",
    requirementLabelEnd: "your incentive by adding an additional leg.",
    requirementLabelStartAdditionalLeg: "There is an opportunity to increase your incentive to ",
    requirementLabelMiddleAdditionalLeg: "{bonus}",
    requirementLabelEndAdditionalLeg: " by adding one additional leg with {volumeRequirement} {volumeType}.",
  },
  bannerTile: {
    onHold: "Your award is on hold.",
    moreInfo: "If you require more information, please contact Customer Support for further details.",
    bfi: {
      keepGoing: "Keep going to earn the Bronze Foundation Incentive+!",
      notEligible: "You were not eligible for the Bronze Foundation Incentive+ in {dateText}",
      greatJob: "Great Job! You are earning the Bronze Foundation Incentive+.",
      manual: "The Bronze Foundation Incentive+ has been manually approved",
    },
    bbi: {
      keepGoing: "Keep going to earn the Bronze Builder Incentive+!",
      notEligible: "You were not eligible for the Bronze Builder Incentive+ in {dateText}",
      greatJob: "Great Job! You are earning the Bronze Builder Incentive+.",
      manual: "The Bronze Builder Incentive+ has been manually approved",
    },
  },
  metric: {
    bfiMultiplier: "Bronze Foundation Incentive+ Multiplier",
    bbiMultiplier: "Bronze Builder Incentive+ Multiplier",
    bronzeConsistencyPaymentMetricMultiplier: "Payment Additional Incentives",
    header: "How is this incentive calculated?",
    performanceBonus: "Performance Bonus",
    currentTotal: "Current Total",
    bronzeBasePayment: "Bronze Base Payment",
    additionalPaymentDetails: "Additional Payment Details",
  },
  bronzeConsistencyPaymentDetails: {
    subTitle: "Consistency Payment Details",
    paymentEarned: "{target}th Payment Earned",
    additionalBonus: "Received {bonus} additional bonus",
  },
  incentiveProgress: {
    title: "Track your progress",
    bronzeProgressText: "You've met <1>{metCount} of {requiredCount}</1> requirements to earn this incentive.",
  },
  bronzeRequirements: {
    header: "How do I qualify? Here is the list of requirements.",
    legsPercentTitle: "{leg} Legs at {percent}",
    viewDownlineButton: "View Downline Information",
    perfBonusLevelTitle: "{perfBonus} Performance Bonus Level",
    perfBonusLevelTag: "Additional <strong>{neededGPV} {volumeType} needed</strong>",
    ppvTitle: "{PPV} PPV",
    vcsPVTitle: "{VCSPV} VCS PV",
    pcpvTitle: "{PCPV} PCPV",
    vcsBonusLegsTitle: "Two {percent} Legs with {vcspv} VCS PV",
    papvTitle: "{PAPV} PAPV",
  },
  eligibilitySlider: {
    eligibleDescription:
      "Eligibility begins the first month you earn the {incentiveType} and lasts for {months} consecutive months. Maximum of 12 payments.",
    startDateLabel: "Start Date",
    endDateLabel: "End Date",
    footerTitle: "Program Details",
    notStartedMessage: "Start and end date will be defined at the end of your first month.",
    footerDescription:
      "Bronze Foundation and Builder Incentives+ reward you for coaching new business owners you sponsor to sell products while you grow your volume, too. The higher your Performance Bonus, the higher your Bronze Incentives.",
  },
};

export default content;
