import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import defaultContent from "../../content/bronzeGroupTracking";
import commonContent from "../../content/common";
import "./BronzeGroupTracking.scss";
import LeafTabViews, { TabView } from "../../common/leaf/LeafTabViews/LeafTabViews";
import { useAppDispatch, useAppSelector } from "../../store";
import { setActiveTabView } from "../../reducers/bronzeGroupTrackingTab";
import { IconInfoCircle } from "../../common/icons/IconInfoCircle/IconInfoCircle";
import { ErrorPage, ERROR_TYPE } from "../ErrorPage/ErrorPage";
import { updateBronzeGroupList, closeProfileModal } from "../../reducers/BronzeGroupTracking/bronzeGroupTracking";
import { useOverviewBGTPageLoadTrack } from "../../common/tealium/BronzeGroupTracking/IndexTrack";
import {
  bgtInfoIconClickActionTrack,
  bgtTabMenuClickActionTrack,
} from "../../common/tealium/BronzeGroupTracking/BronzeGroupTracking";
import { BGT_TABS } from "../../common/enums/bronze-group-tracking-enums";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import BGTGraduated from "./BGTGraduated/BGTGraduated";
import BGTOverview from "./BGTOverview/BGTOverview";
import BGTTracking from "./BGTTracking/BGTTracking";
import BGTInfoModal from "./BGTInfoModal/BGTInfoModal";
import { FetchBronzeGroupTrackingData } from "./BronzeGroupTrackingDataLayer";
import { MiniProfile } from "./MiniProfile/MiniProfile";

export default function BronzeGroupTracking() {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const { activeView, activeTabs } = useAppSelector((state) => state.bronzeGroupTrackingTab);
  const dispatch = useAppDispatch();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { showProfile } = useAppSelector((state) => state.bronzeGroupTracking);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useOverviewBGTPageLoadTrack({ period: selectedPeriod });

  const setTrackingView = () => {
    dispatch(setActiveTabView(BGT_TABS.TRACKING_TAB));
  };

  const closeMiniProfile = () => {
    dispatch(closeProfileModal());
  };

  const { bronzeTrackingData, isLoading, errorStatus } = FetchBronzeGroupTrackingData(selectedPeriod);

  const internalTabsView = [
    {
      id: BGT_TABS.OVERVIEW_TAB,
      title: t("overview", defaultContent["overview"]),
      View: <BGTOverview onAllTrackingClick={setTrackingView} />,
    },
    {
      id: BGT_TABS.TRACKING_TAB,
      title: t("tracking", defaultContent["tracking"]),
      View: <BGTTracking />,
    },
    {
      id: BGT_TABS.GRADUATED_TAB,
      title: t("graduated", defaultContent["graduated"]),
      View: <BGTGraduated />,
    },
  ];

  const tabsView = activeTabs.map((id: string) => ({
    ...internalTabsView.find((item) => item.id === id),
  })) as TabView[];

  const setViewOnClick = (activeView: BGT_TABS) => {
    dispatch(setActiveTabView(activeView));
    bgtTabMenuClickActionTrack(activeView);
  };

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
    bgtInfoIconClickActionTrack(activeView);
  };

  const ErrorComponent = () => {
    return errorStatus ? <ErrorPage errorType={ERROR_TYPE.GENERIC_ERROR} isLoading={isLoading} /> : <></>;
  };

  useEffect(() => {
    const { trackingTilesData = [], graduatedTilesData = [] } = bronzeTrackingData.awardProgress;
    const { requirementFilters = [] } = bronzeTrackingData;

    dispatch(
      updateBronzeGroupList({
        initialTrackingTilesData: trackingTilesData,
        initialGraduatedTilesData: graduatedTilesData,
        finalTrackingTilesData: trackingTilesData,
        finalGraduatedTilesData: graduatedTilesData,
        requirementFilters: requirementFilters,
      }),
    );
  }, [bronzeTrackingData, dispatch]);

  return (
    <main>
      <ErrorComponent />
      <div className={`bronzeGroupTracking ${errorStatus ? "hidden" : ""}`} id="bronze-group-tracking">
        <PageTitleSection
          title={`${t("pageTitle", defaultContent["pageTitle"] as string)}`}
          infoButton={
            <button
              aria-label={t("common:moreInfo", commonContent["moreInfo"]) as unknown as string}
              className="bronzeGroupTracking__info-button"
              onClick={handleShowModal}
            >
              <IconInfoCircle />
            </button>
          }
        />
        <BGTInfoModal
          title={`${t(
            "informationDrawerContent.informationDrawerHeader",
            defaultContent["informationDrawerContent"]["informationDrawerHeader"] as string,
          )}`}
          show={showModal}
          onCloseClick={handleModalClose}
          id={"BGT-info-modal"}
        />
        <section className="bronzeGroupTracking__body" id="bronzeGroupTracking-tabs">
          <LeafTabViews
            setViewOnClick={setViewOnClick}
            Views={tabsView}
            activeView={activeView}
            isLoading={isLoading || bronzeTrackingData.requirementFilters.length === 0}
          ></LeafTabViews>
        </section>
        <BonusInfoFooter />
      </div>
      <MiniProfile
        show={showProfile}
        onCloseClick={closeMiniProfile}
        title={`${t("pageTitle", defaultContent["pageTitle"] as string)}`}
        id="BGT-mini-profile-modal"
      />
    </main>
  );
}
