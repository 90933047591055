import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes, storedIsoCountry } from "../../common/util/config";
import { fetchContent, storedLocale } from "../impersonation/util";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import ABOProfile from "../ABOProfile/ABOProfile";
import { ErrorPage, ERROR_TYPE } from "../ErrorPage/ErrorPage";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { useActionReportMenuOptions } from "./hooks/useActionReportMenuOptions";
import { getARComponentConfiguration } from "./utils/ActionReportHelpers";
import "./ActionReports.scss";

const loadComponent = async () => {
  return import("../Lit/acc-wc-los-ar/acc-wc-los-ar.js");
};

export default function BirthdaysActionReport() {
  const locale = storedLocale();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const { birthdaysAr = {} } = getARComponentConfiguration(configuration);
  const { actionReportMenuOptions } = useActionReportMenuOptions();
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState(false);
  const [disclaimer, setDisclaimer] = useState("");
  const pageId = "los";
  const isCountryCode = storedIsoCountry();
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const enableABOProfileSlider = configuration?.profile?.enableABOProfileSlider;
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  const updatedBirthdaysAr = {
    ...birthdaysAr,
    uiGrid: birthdaysAr.uiGrid
      .split(",")
      .filter((item: string) => (enableABOProfileSlider === true ? item !== "uiMenuShowPerfHistory" : true))
      .join(","),
  };

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));

    fetchContent(pageId, isCountryCode, locale, {}).then((content) => {
      setDisclaimer(content.footerDisclaimerMD);
    });
  }, [isCountryCode, locale, dispatch]);

  useEffect(() => {
    if (!isPending) {
      const birthdaysArComponent = document.getElementById("acc_wc_los_ar_birthdays_component") as HTMLElement;
      mapAttributes(birthdaysArComponent, updatedBirthdaysAr, {});
    }
  }, [isPending, birthdaysAr]);

  useEffect(() => {
    if (!apiError) {
      window.addEventListener("acc-api-error", () => setApiError(true));
    }

    if (apiError) {
      window.removeEventListener("acc-api-error", () => {});
    }
  }, [apiError]);

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, []);

  const showABOProfile = (event: CustomEventInit) => {
    if (enableABOProfileSlider === true) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    }
  };

  if (apiError) {
    return <ErrorPage errorType={ERROR_TYPE.GENERIC_ERROR} />;
  }

  if (isPending) {
    return <div className="actionReportWrapper"></div>;
  }

  const renderBirthdayAR = () => {
    if (!enableABOProfileSlider) {
      return (
        <acc-wc-los-ar
          id="acc_wc_los_ar_birthdays_component"
          locale={locale.toLowerCase()}
          period={selectedPeriod}
          disclaimer={disclaimer}
          uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        ></acc-wc-los-ar>
      );
    }
    return (
      <acc-wc-los-ar
        id="acc_wc_los_ar_birthdays_component"
        locale={locale.toLowerCase()}
        period={selectedPeriod}
        disclaimer={disclaimer}
        uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        enableABOProfileSlider
      ></acc-wc-los-ar>
    );
  };
  return (
    <main>
      <div id="ar-birthdays-wrapper" className="actionReportWrapper">
        {renderBirthdayAR()}
      </div>
      <ABOProfile previousElement={modalOpenedByElement} />
      <BonusInfoFooter hideDate />
    </main>
  );
}
