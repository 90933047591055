import { useTranslation } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/bronzeIncentives";
import "./TrackingRequirementTile.scss";
import { IconCheckCircle } from "../../../../common/icons/IconCheckCircle/IconCheckCircle";
import { IconPersonPlus } from "../../../../common/icons/IconPersonPlus/IconPersonPlus";
import { storedLocale } from "../../../impersonation/util";
import { useAppSelector } from "../../../../store";
import { GetVolumeTypeLabel, TrackingReqData } from "../pathToBronzeUtils";
import { LegVolumeReq } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type Props = {
  isLoading: boolean;
  circleCheckmarkId?: string;
  trackingRequirementData: TrackingReqData;
  legReqVolData: LegVolumeReq;
  bonusCurrency: string;
};

export default function TrackingRequirementTile({
  isLoading,
  trackingRequirementData,
  legReqVolData,
  bonusCurrency,
  circleCheckmarkId,
}: Props) {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { user } = useAppSelector((state) => state.boot);
  const locale = storedLocale();
  const market = user.isoCountryCode;
  const volumeType = GetVolumeTypeLabel(legReqVolData.requirement);

  // hide checkmark icon if requirement status is false
  const getVisibilityClass = (visible: boolean) => {
    return visible === false ? "--hidden" : "";
  };

  const renderCurrentBonusIcon = (trackingRequirementData: TrackingReqData) => {
    if (trackingRequirementData.totalLegRequirements < 3) {
      return (
        <IconPersonPlus
          id="tracking-icon-four1"
          color={trackingRequirementData.extraBonusesMet > 0 ? "green" : "gray"}
          size="lg"
        />
      );
    }

    return (
      <>
        <IconPersonPlus
          id="tracking-icon-four2"
          color={trackingRequirementData.extraBonusesMet > 0 ? "green" : "gray"}
          size="lg"
        />
        <IconPersonPlus
          id="tracking-icon-four3"
          color={trackingRequirementData.extraBonusesMet > 1 ? "green" : "gray"}
          size="lg"
        />
      </>
    );
  };

  const createTrackingIconGroup = () => {
    return (
      <div className="tracking-req-tile__icon-container">
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[0])}>
          <IconCheckCircle
            id="tracking-icon-one"
            color={trackingRequirementData.firstLegsMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[1])}>
          <IconCheckCircle
            id="tracking-icon-two"
            color={trackingRequirementData.perfBonusMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[2])}>
          <IconCheckCircle
            id="tracking-icon-three"
            color={trackingRequirementData.vcsMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[3])}>
          <IconCheckCircle
            id="tracking-icon-four"
            color={trackingRequirementData.ppvMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className="tracking-req-tile__vertical-line"></span>
        {renderCurrentBonusIcon(trackingRequirementData)}
      </div>
    );
  };

  const renderLegContent = () => {
    const { extraBonusesMet } = trackingRequirementData;
    switch (extraBonusesMet) {
      case 1:
        return (
          <span>{`${t(
            "trackingRequirementTile.inProgressLabelEndAdditionalLeg",
            defaultContent["trackingRequirementTile"]["inProgressLabelEndAdditionalLeg"],
            {
              volumeRequirement: legReqVolData.volumeRequired.formatted,
              volumeType: volumeType,
            },
          )}`}</span>
        );
      case 2:
        return (
          <span>{`${t(
            "trackingRequirementTile.inProgressLabelEndAdditionalLegs",
            defaultContent["trackingRequirementTile"]["inProgressLabelEndAdditionalLegs"],
            {
              volumeRequirement: legReqVolData.volumeRequired.formatted,
              volumeType: volumeType,
            },
          )}`}</span>
        );
      case 0:
      default:
        return (
          <span>{`${t(
            "trackingRequirementTile.inProgressLabelEnd",
            defaultContent["trackingRequirementTile"]["inProgressLabelEnd"],
          )}`}</span>
        );
    }
  };

  const renderContentForCurrentBonus = () => {
    const { metEverything } = trackingRequirementData;
    const totalRequirementsMet = trackingRequirementData.legsMetCount - trackingRequirementData.totalLegsCount;

    if (metEverything) {
      return (
        <div className="tracking-req-tile__desc">
          <span>{`${t(
            "trackingRequirementTile.metlabel",
            defaultContent["trackingRequirementTile"]["metlabel"],
          )}`}</span>
        </div>
      );
    }

    if (totalRequirementsMet !== 0) {
      return (
        <div className="tracking-req-tile__desc">
          <span>{`${t(
            "trackingRequirementTile.requirementLabelStart",
            defaultContent["trackingRequirementTile"]["requirementLabelStart"],
          )}`}</span>
          <span className="tracking-req-tile__new-legs">{`${t(
            "trackingRequirementTile.requirementLabelMiddle",
            defaultContent["trackingRequirementTile"]["requirementLabelMiddle"],
          )}`}</span>
          <span>{`${t(
            "trackingRequirementTile.requirementLabelEnd",
            defaultContent["trackingRequirementTile"]["requirementLabelEnd"],
          )}`}</span>
        </div>
      );
    }

    return (
      <div className="tracking-req-tile__desc">
        <span>{`${t(
          "trackingRequirementTile.requirementLabelStartAdditionalLeg",
          defaultContent["trackingRequirementTile"]["requirementLabelStartAdditionalLeg"],
        )}`}</span>
        <span className="tracking-req-tile__new-legs">{`${t(
          "trackingRequirementTile.requirementLabelMiddleAdditionalLeg",
          defaultContent["trackingRequirementTile"]["requirementLabelMiddleAdditionalLeg"],
          {
            bonus: trackingRequirementData.nextBonusAmount.formatted,
          },
        )}`}</span>
        <span>{`${t(
          "trackingRequirementTile.requirementLabelEndAdditionalLeg",
          defaultContent["trackingRequirementTile"]["requirementLabelEndAdditionalLeg"],
          {
            volumeRequirement: legReqVolData.volumeRequired.formatted,
            volumeType: volumeType,
          },
        )}`}</span>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <section className="tracking-req-tile__desc-container">
        <div className="tracking-req-tile__desc">
          <span>{`${t(
            "trackingRequirementTile.inProgressLabelStart",
            defaultContent["trackingRequirementTile"]["inProgressLabelStart"],
          )}`}</span>
          <span className="tracking-req-tile__new-legs">
            {`${t(
              "trackingRequirementTile.inProgressLabelMiddle",
              defaultContent["trackingRequirementTile"]["inProgressLabelMiddle"],
              {
                x: trackingRequirementData.legsMetCount,
                y: trackingRequirementData.totalLegsCount,
              },
            )}`}
          </span>
          {renderLegContent()}
        </div>
        {renderContentForCurrentBonus()}
      </section>
    );
  };

  return (
    <div id="leafTrackingReqTile" className="tracking-req-tile">
      <LeafTile
        title={`${t("trackingRequirementTile.title", defaultContent["trackingRequirementTile"]["title"])}`}
        isLoading={isLoading}
        circleCheckmarkId=""
      >
        <section>{createTrackingIconGroup()}</section>
        {renderContent()}
      </LeafTile>
    </div>
  );
}
