import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes } from "../../common/util/config";
import { storedLocale } from "../impersonation/util";
import { useAppDispatch, useAppSelector } from "../../store";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import ABOProfile from "../ABOProfile/ABOProfile";
import { showProfile } from "../../reducers/profile";
import { LOSExportModal } from "../ServerSideExport/LOSExportModal/LOSExportModal";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import "./RwLos.scss";

const loadComponent = async () => {
  return import("../Lit/acc-wc-los-ar/acc-wc-los-ar.js");
};

export default function LOS() {
  const locale = storedLocale();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const enableABOProfileSlider = configuration?.profile?.enableABOProfileSlider;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state, search } = location;
  const [openExportModal, setExportModalState] = useState(false);
  const queryParams = new URLSearchParams(search);

  let { targetAbo = "" } = state || {};
  if (!targetAbo) {
    targetAbo = queryParams.get("targetAbo") || "";
  }

  // allow to turn on v1 export
  const enableExportV1 = queryParams.get("enableExportV1") === "true";
  const enableExportV2 = enableExportV1 ? "false" : configuration?.los?.enableExportV2;

  const { isPending } = useAsync({ promiseFn: loadComponent });
  const updatedRwlos = {
    ...configuration.rwlos,
    uiGrid: configuration.rwlos.uiGrid
      .split(",")
      .filter((item) => (enableABOProfileSlider === true ? item !== "uiMenuShowPerfHistory" : true))
      .join(","),
  };
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    if (!isPending) {
      const rwlos = document.getElementById("acc_wc_los_ar_component");

      mapAttributes(rwlos, updatedRwlos || {}, {});
    }
  }, [isPending, configuration.rwlos]);

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("period-changed", {
        bubbles: true,
        composed: true,
        detail: {
          period: selectedPeriod,
        },
      }),
    );
  }, [selectedPeriod]);

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("OpenExportV2Modal", showDownloadModal);
    return () => {
      window.removeEventListener("OpenExportV2Modal", showDownloadModal);
    };
  }, []);

  const showDownloadModal = (event: CustomEventInit) => {
    setExportModalState(true);
  };

  useEffect(() => {
    window.addEventListener("ExportDetails", triggerExportAPI);
    return () => {
      window.removeEventListener("ExportDetails", triggerExportAPI);
    };
  }, []);

  const triggerExportAPI = (event: CustomEventInit) => {
    // To-Do: Fire API Request to export API to trigger the export process
  };

  const showABOProfile = (event: CustomEventInit) => {
    if (enableABOProfileSlider === true) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(
        event.detail.target.parentElement.id === "rootAbo" ? event.detail.target.parentElement : event.detail.target,
      );
    }
  };

  const onModalClose = () => {
    setExportModalState(false);
  };

  if (isPending) {
    return <div className="RwLos__wrapper"></div>;
  }

  const renderLosAr = () => {
    if (!enableABOProfileSlider) {
      return (
        <acc-wc-los-ar
          data-testid="rwlos"
          id="acc_wc_los_ar_component"
          hidePeriodSelector
          mode="standalone"
          lang={locale}
          period={selectedPeriod}
          locale={locale}
          losviewtype="los"
          searchTerm={targetAbo}
          enableExportV2={`${enableExportV2}`}
        ></acc-wc-los-ar>
      );
    }
    return (
      <acc-wc-los-ar
        data-testid="rwlos"
        id="acc_wc_los_ar_component"
        hidePeriodSelector
        mode="standalone"
        lang={locale}
        period={selectedPeriod}
        locale={locale}
        losviewtype="los"
        searchTerm={targetAbo}
        enableABOProfileSlider
        enableExportV2={`${enableExportV2}`}
      ></acc-wc-los-ar>
    );
  };

  return (
    <main>
      <div id="rwlos-wrapper" className="RwLos__wrapper">
        {renderLosAr()}
      </div>
      <ABOProfile previousElement={modalOpenedByElement} />
      <LOSExportModal open={openExportModal} onCloseCallbackFn={onModalClose} />
      <BonusInfoFooter hideDate />
    </main>
  );
}
