import { VcsPercentRequirementTile } from "../../../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
  vcsPV: number;
  personalPV: number;
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementVCSPercent = (props: Props) => {
  const {
    met = false,
    required = false,
    target = 0,
    short = 0,
    vcsPV = 0,
    personalPV = 0,
    isFetching,
    actual = 0,
  } = props;

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <VcsPercentRequirementTile
      isLoading={isFetching}
      additionalNeeded={short}
      targetValue={target}
      shouldShowGraphics={true}
      leftValue={vcsPV}
      rightValue={personalPV}
      met={met}
      percent={actual}
      circleCheckmarkId={"BronzeReqVcsPercent"}
    />
  );
};

export default BronzeRequirementVCSPercent;
