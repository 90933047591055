import { AWARD_CODES } from "../../enums/award-codes";
import { NavData, NavDropdown } from "../../interfaces/routing";
import { ShellConfig } from "../../../reducers/model/boot";
import { ShellConfigApiResponse } from "../../../services/ShellConfig/getShellConfigAPI.types";
import { ROUTE_PATHS } from "../../enums/routing-enums";
import { isProgramActiveBulkCheck } from "../checkProgramActiveStatus";
import { createMenuConfig } from "./createMenuConfig";

/**
 * navigationAwardCodes are codes that have no associatied incentive award code. So they
 * are added here.
 */
const navigationAwardCode: { [key: string]: number } = {
  enableMgd: AWARD_CODES.MGD,
  showActionReports: AWARD_CODES.ACTION_REPORTS,
  showCalculator: AWARD_CODES.EARNINGS_CALCULATOR,
  showGlobalCml: AWARD_CODES.CUSTOMER_OVERVIEW,
  showEdLos: AWARD_CODES.EDLOS,
  showLOS: AWARD_CODES.LOS,
  showManual: AWARD_CODES.LEARN_ABOUT_CPP,
  showWatchlist: AWARD_CODES.WATCHLIST,
  showBronzeGroupTracking: AWARD_CODES.BRONZE_GROUP_TRACKING,
  showHAT: AWARD_CODES.HAT,
  showFAA: AWARD_CODES.FAA,
  showMBI: AWARD_CODES.MBI,
  showBronzeIncentives: AWARD_CODES.BRONZE_INCENTIVES,
  showQCG: AWARD_CODES.QCG,
  showVisionBonus: AWARD_CODES.VISION_BONUS,
};

const CreateNavDropdownItem = (awardNumber: number, programActive: boolean, isoCountryCode: string) => {
  return {
    isoCountry: isoCountryCode,
    awardNumber,
    bonusPeriod: 0,
    effectivePeriod: 0,
    firstEffectivePeriod: 0,
    programActive,
    ineligibleBusinessNatures: [],
    comingSoon: false,
  };
};

const handleEdLos = (
  isActive: boolean,
  shellConfig: ShellConfigApiResponse,
  awardCode: number,
  isoCountryCode: string,
): NavData | null => {
  if (isActive && shellConfig.hasAchievedEmeraldOrHigher) {
    return CreateNavDropdownItem(awardCode, true, isoCountryCode);
  }
  return null;
};

const handleBronzeGroupTracking = (isActive: boolean, shellConfig: ShellConfigApiResponse) => {
  const bronzeGroupTracking = shellConfig.eligibleIncentives.find(
    (incentive) => incentive.awardNumber === AWARD_CODES.BRONZE_GROUP_TRACKING,
  );
  if (bronzeGroupTracking) {
    bronzeGroupTracking.programActive = bronzeGroupTracking.programActive && isActive;
  }
};

/**
 * If feature flag is false the array of eligibleIncentive should filter out the FAA code.
 *
 * @param isActive
 * @param shellConfig
 */
const handleFAA = (isActive: boolean, shellConfig: ShellConfigApiResponse) => {
  if (!isActive) {
    shellConfig.eligibleIncentives = shellConfig.eligibleIncentives.filter(
      (incentive) => incentive.awardNumber !== AWARD_CODES.FAA,
    );
  }
};

/**
 * As of now the coreplus shell api will not return data inside eligibleIncentives for HAT and it will depend on shouldShowHAT flag
 *
 * @param isActive
 * @param shellConfig
 * @param awardCode
 * @param isoCountryCode
 * @returns
 */
const handleHAT = (
  isActive: boolean,
  shellConfig: ShellConfigApiResponse,
  awardCode: number,
  isoCountryCode: string,
): NavData | null => {
  if (isActive && shellConfig.shouldShowHAT) {
    return CreateNavDropdownItem(awardCode, true, isoCountryCode);
  }
  return null;
};

const handleBronzeIncentive = (
  isActive: boolean,
  shellConfig: ShellConfigApiResponse,
  awardCode: number,
  isoCountryCode: string,
): NavData | null => {
  const BRONZE_INCENTIVES = [
    AWARD_CODES.PATH_TO_BRONZE,
    AWARD_CODES.BRONZE_FOUNDATION,
    AWARD_CODES.BRONZE_BUILDER,
    AWARD_CODES.MN_BRONZE_FOUNDATION,
    AWARD_CODES.MN_BRONZE_BUILDER,
  ];

  const programActive = isProgramActiveBulkCheck(shellConfig.eligibleIncentives, BRONZE_INCENTIVES);
  const bronzeIncentive = programActive && isActive;
  return CreateNavDropdownItem(awardCode, bronzeIncentive, isoCountryCode);
};

/**
 * Creates the navigationData with the active and inactive incentives/pages based on the cppConfig and shellConfig
 * data.
 *
 * @param cppConfig
 * @param shellConfigData
 * @returns
 */
export const createNavigationData = (cppConfig: any, shellConfigData: ShellConfigApiResponse): NavData[] => {
  const navigationData: NavData[] = [];
  const shellConfig = JSON.parse(JSON.stringify(shellConfigData)) as ShellConfig;
  const { isoCountryCode = "" } = shellConfig.aboInfo;

  Object.keys(navigationAwardCode).forEach((key) => {
    const isActive = cppConfig.navigation[key];
    const awardCode = navigationAwardCode[key];

    let navItem: NavData | null = null;
    switch (awardCode) {
      case navigationAwardCode.showEdLos:
        navItem = handleEdLos(isActive, shellConfig, awardCode, isoCountryCode);
        break;
      case navigationAwardCode.showBronzeGroupTracking:
        handleBronzeGroupTracking(isActive, shellConfig);
        break;
      case navigationAwardCode.showFAA:
        handleFAA(isActive, shellConfig);
        break;
      case navigationAwardCode.showHAT:
        navItem = handleHAT(isActive, shellConfig, awardCode, isoCountryCode);
        break;
      case navigationAwardCode.showBronzeIncentives:
        navItem = handleBronzeIncentive(isActive, shellConfig, awardCode, isoCountryCode);
        break;
      default:
        navItem = CreateNavDropdownItem(awardCode, isActive, isoCountryCode);
        break;
    }

    if (navItem) {
      navigationData.push(navItem);
    }
  });

  return [...shellConfig.eligibleIncentives, ...navigationData] as NavData[];
};

export const createMenuConfiguration = (bootState: any, shellApiData: ShellConfigApiResponse) => {
  const { configuration } = bootState;
  const navigationData = createNavigationData(configuration, shellApiData);
  return createMenuConfig(navigationData, configuration.userManual.userManualLink, bootState);
};

export const getNavItemForLabel = (menuConfig: NavDropdown[], routeLabel: string) => {
  return menuConfig.flatMap((nav) => nav.items).find((item) => item.label === routeLabel);
};

export const getRoutePathForLabel = (menuConfig: NavDropdown[], routeLabel: string) => {
  const navItem = getNavItemForLabel(menuConfig, routeLabel);
  return navItem ? navItem.path : "";
};

export const getNavItemForAwardCode = (menuConfig: NavDropdown[], awardCode: number) => {
  if (awardCode === AWARD_CODES.PERFORMANCE_PLUS_ELITE) {
    return menuConfig
      .flatMap((nav) => nav.items)
      .find((item) => item.code === AWARD_CODES.PERFORMANCE_ELITE || item.code === AWARD_CODES.PERFORMANCE_PLUS);
  }
  return menuConfig.flatMap((nav) => nav.items).find((item) => item.code === awardCode);
};

export const getRoutePathForAwardCode = (menuConfig: NavDropdown[], awardCode: number) => {
  const navItem = getNavItemForAwardCode(menuConfig, awardCode);

  return navItem ? navItem.path : "";
};

export const getDefaultHomePage = (configValue: string) => {
  return configValue === "/" ? ROUTE_PATHS.PERFORMANCE_DASHBOARD : configValue;
};
