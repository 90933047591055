import { useTranslation } from "react-i18next";
import LeafTile, { Props as LeafTileProps } from "../../../../common/leaf/LeafTile/LeafTile";
import LeafButton from "../../../../common/leaf/LeafButton/LeafButton";
import defaultContent from "../../../../content/bronzeIncentives";
import { storedLocale } from "../../../impersonation/util";
import "./EligibilityTile.scss";
import { getLongPeriodFormat, getSelectedPerformanceYearData } from "../../../../common/util/period";
import {
  BonusHistory,
  PathToBronzeOverviewResponse,
} from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type EligibilityTileProps = {
  data: PathToBronzeOverviewResponse;
  period: string;
  handleButtonClick: Function;
} & LeafTileProps;

const getEligibilityEndPeriod = (bonusHistory: BonusHistory[], locale: string, selectedPeriod: string): string => {
  if (!bonusHistory.length || bonusHistory.length === 0) {
    const { selectedPYEndPeriod } = getSelectedPerformanceYearData(selectedPeriod, 0);
    return getLongPeriodFormat(selectedPYEndPeriod, locale);
  }
  const finalPeriod = bonusHistory[bonusHistory.length - 1].period;
  return getLongPeriodFormat(finalPeriod, locale);
};

function EligibilityTile({ data, isLoading, period, handleButtonClick }: EligibilityTileProps) {
  const { t } = useTranslation(["bronzeIncentives"]);
  const locale = storedLocale();

  const { ineligibleForPY, awardCap, perfBonusLevel, graduated, bonusHistory } = data;
  const monthYYYY = getEligibilityEndPeriod(bonusHistory, locale, period);

  const BonusAmountDescription = () => {
    return (
      <div>
        {`${t(
          "newAboActivation.eligibilityTile.description",
          defaultContent["newAboActivation"]["eligibilityTile"]["description"],
        )}`}
        <span className="ptbEligibilityTile__formatRemainingTimes">
          {`${t(
            "newAboActivation.eligibilityTile.descriptionRemaining",
            defaultContent["newAboActivation"]["eligibilityTile"]["descriptionRemaining"],
            {
              remainingTimes: awardCap.remaining,
            },
          )}`}
        </span>
        {`${t(
          "newAboActivation.eligibilityTile.descriptionPeriod",
          defaultContent["newAboActivation"]["eligibilityTile"]["descriptionPeriod"],
          { monthYYYY },
        )}`}
      </div>
    );
  };

  const ineligibleDescription = t(
    "newAboActivation.eligibilityTile.ineligibleDescription",
    defaultContent["newAboActivation"]["eligibilityTile"]["ineligibleDescription"],
    {
      achieveLimit: awardCap.target,
    },
  );

  const getContent = () => {
    let title;
    let description;
    if (perfBonusLevel.required || awardCap.target > 0) {
      title = `${t(
        "newAboActivation.eligibilityTile.title",
        defaultContent["newAboActivation"]["eligibilityTile"]["title"],
      )}`;
      description = <BonusAmountDescription />;
    }
    return { title, description };
  };

  return (
    <>
      {!ineligibleForPY && !graduated ? (
        <LeafTile
          title={getContent().title}
          description={getContent().description}
          isLoading={isLoading}
          circleCheckmarkId=""
          id="ptbEligibilityTile"
        >
          <LeafButton clickAction={handleButtonClick} id="ptbEligibilityButton">
            {`${t(
              "newAboActivation.eligibilityTile.viewEligibility",
              defaultContent["newAboActivation"]["eligibilityTile"]["viewEligibility"],
            )}`}
          </LeafButton>
        </LeafTile>
      ) : null}
    </>
  );
}

export default EligibilityTile;
