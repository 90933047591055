import { useEffect } from "react";
import { fetchConfig } from "../../common/util/config";
import { AUTH_ROUTE_PATHS } from "../../routing/AuthenticationRoutes";
import { getAffAbo } from "../impersonation/util";
import { store } from "../../store";
import { setLocalStorageItem } from "../../reducers/loginLocalStorage";
import { useHandleCookie } from "../../common/util/cookies";

export default function LogOut() {
  const { deleteCookies } = useHandleCookie();

  useEffect(() => {
    const loginOrigin = sessionStorage.getItem("loginOrigin");

    const deleteCache = () => {
      caches.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            if (key.indexOf("$__acc") > -1) return caches.delete(key);
            return Promise.resolve();
          }),
        );
      });
    };

    const deleteRequestCache = () => {
      const deleteDbRequestCache = indexedDB.deleteDatabase("RequestCache");
      deleteDbRequestCache.onerror = (event) => {
        console.warn("ACC-LOAD: Failed deleting RequestCache.");
      };
      deleteDbRequestCache.onsuccess = (event) => {
        console.log("ACC-LOAD: RequestCache deleted.");
      };
    };

    const clearStorage = async () => {
      store.dispatch(setLocalStorageItem({ clientId: "" }));
      sessionStorage.clear();
      deleteCookies();
      deleteCache();
      deleteRequestCache();
    };

    const redirectMarketHomePage = async () => {
      const config = await fetchConfig();
      clearStorage();
      window.location.href = config.secureLinkRedirectPath ? config.secureLinkRedirectPath : window.location.origin;
    };

    // Added this check to make sure if there is gluIdpSessionLogoutUrl not empty in config then session out using the idpSessionLogoutUrl.
    const getLogoutRawLink = (config: any) => {
      const { gluIdpSessionLogoutUrl, logoutLink, useGluuAuth } = config;
      if (useGluuAuth && gluIdpSessionLogoutUrl) {
        return gluIdpSessionLogoutUrl;
      }
      return logoutLink;
    };

    const redirectOnLogOut = async () => {
      const { aff, abo } = await getAffAbo();
      const loginFielads = store.getState().loginLocalStorage.loginFields;
      const ssoOrigin = loginFielads.ssoOrigin;
      const locale = loginFielads.locale;
      const config = await fetchConfig();
      const { amwayIdUrlBase, websiteUrlBase } = config;
      const href = getLogoutRawLink(config).replace(
        /{{AMWAYID_BASE_URL}}|{{WEBSITE_URL_BASE}}|{{ISO_COUNTRY_CODE}}|{{AFF}}|{{ABO}}|{{LOCALE}}/g,
        function (matched: any) {
          switch (matched) {
            case "{{AMWAYID_BASE_URL}}":
              return amwayIdUrlBase;
            case "{{WEBSITE_URL_BASE}}":
              return websiteUrlBase;
            case "{{ISO_COUNTRY_CODE}}":
              return ssoOrigin;
            case "{{AFF}}":
              return aff;
            case "{{ABO}}":
              return abo;
            case "{{LOCALE}}":
              return locale;
          }
        },
      );
      await clearStorage();
      window.location.href = href;
    };

    if (loginOrigin === "impersonation") {
      window.location.replace(window.location.origin + AUTH_ROUTE_PATHS.IMPERSONATION);
      clearStorage();
    } else if (loginOrigin === "amwayid") {
      redirectOnLogOut();
    } else {
      redirectMarketHomePage();
    }
  }, [deleteCookies]);

  return <></>;
}
