import { formatCurrency } from "@amwaycommon/acc-mixins/l10n";
import { Trans, useTranslation } from "react-i18next";
import { TTCI_STATUS } from "../../../common/enums/ttci-enums";
import { TTCIResponse } from "../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { LeafColorBlock } from "../../../common/leaf/LeafColorBlock/LeafColorBlock";
import { LeafMessageBox } from "../../../common/leaf/LeafMessageBox/LeafMessageBox";
import { getDefaultCurrencyCode } from "../../../common/util/config";
import defaultContent from "../../../content/ttci";
import "./BannerTile.scss";
import { LeafLearnMoreLink } from "../../../common/leaf/LeafLearnMoreLink/LeafLearnMoreLink";
import { getPerformanceYearShort } from "../../../common/util/period";

type Props = {
  ttciData: TTCIResponse;
  isNewPerformanceYear: boolean;
  period: string;
  country: string;
  locale: string;
  learnMoreLink: string;
  isLoading: boolean;
};

/**
 * TTCI Banner Message Order: TRACKING, ACHIEVED, HELD, MANUALLY_APPROVED, DENIED, NOT_TRACKING, No longer qualified,
 * then fallback to the no longer qualified message if none of the above conditions are met.
 *
 * A70 messages will only be displayed if the ABO is eligible for A70 awards (hasA70Awards).
 */
const getBannerMessage = (ttciData: TTCIResponse, isNewPerformanceYear: boolean) => {
  const { status, manuallyApproved, noLongerQualify, hasA70Awards } = ttciData;

  if (isNewPerformanceYear) return "newPerformanceYearTitle";

  const statusMap: { key: string; condition: boolean }[] = [
    { key: "withA70Tracking", condition: hasA70Awards && status === TTCI_STATUS.TRACKING },
    { key: "onTrackTitle", condition: !hasA70Awards && status === TTCI_STATUS.TRACKING },

    { key: "withA70Achieved", condition: hasA70Awards && status === TTCI_STATUS.ACHIEVED && !manuallyApproved },
    { key: "achievedTitle", condition: !hasA70Awards && status === TTCI_STATUS.ACHIEVED && !manuallyApproved },

    { key: "withA70OnHold", condition: hasA70Awards && status === TTCI_STATUS.HELD },
    { key: "onHoldTitle", condition: !hasA70Awards && status === TTCI_STATUS.HELD },

    { key: "withA70ManuallyApproved", condition: hasA70Awards && manuallyApproved },
    { key: "manuallyApprovedTitle", condition: !hasA70Awards && manuallyApproved },

    { key: "withA70Denied", condition: hasA70Awards && status === TTCI_STATUS.DENIED },
    { key: "deniedTitle", condition: !hasA70Awards && status === TTCI_STATUS.DENIED },

    { key: "withA70NotTracking", condition: hasA70Awards && !noLongerQualify && status === TTCI_STATUS.NOT_TRACKING },
    { key: "notTracking", condition: !hasA70Awards && !noLongerQualify && status === TTCI_STATUS.NOT_TRACKING },

    { key: "withA70NoLongerQualifiedTitle", condition: hasA70Awards && noLongerQualify },
    { key: "noLongerQualifiedTitle", condition: !hasA70Awards && noLongerQualify },
  ];

  const fallbackMessage = hasA70Awards ? "withA70NoLongerQualifiedTitle" : "noLongerQualifiedTitle";
  return statusMap.find((entry) => entry.condition)?.key ?? fallbackMessage;
};

/**
 * The banner subtitle will follow same precedence as the banner title.
 */
const getBannerSubtitle = (ttciData: TTCIResponse, isNewPerformanceYear: boolean) => {
  const { status, manuallyApproved, noLongerQualify, hasA70Awards } = ttciData;

  if (isNewPerformanceYear) return "newPerformanceYearSubTitle";

  const subtitleMap: { key: string; condition: boolean }[] = [
    { key: "contactAmway", condition: status === TTCI_STATUS.DENIED || status === TTCI_STATUS.HELD },

    { key: "withA70Subtext", condition: hasA70Awards && noLongerQualify && !manuallyApproved },
    { key: "earnTtciSubTitle", condition: !hasA70Awards && noLongerQualify && !manuallyApproved },

    { key: "withA70Subtext", condition: hasA70Awards && status === TTCI_STATUS.TRACKING },
    { key: "earnTtciSubTitle", condition: !hasA70Awards && status === TTCI_STATUS.TRACKING },

    { key: "withA70Subtext", condition: hasA70Awards && status === TTCI_STATUS.ACHIEVED && !manuallyApproved },
    { key: "earnTtciSubTitle", condition: !hasA70Awards && status === TTCI_STATUS.ACHIEVED && !manuallyApproved },

    { key: "withA70Subtext", condition: hasA70Awards && status === TTCI_STATUS.NOT_TRACKING && !manuallyApproved },
    { key: "earnTtciSubTitle", condition: !hasA70Awards && status === TTCI_STATUS.NOT_TRACKING && !manuallyApproved },

    { key: "contactAmway", condition: manuallyApproved },
  ];

  const fallbackMessage = hasA70Awards ? "withA70Subtext" : "earnTtciSubTitle";
  return subtitleMap.find((entry) => entry.condition)?.key ?? fallbackMessage;
};

const hidePotentialEarningsSection = (isNewPerformanceYear: boolean, noLongerQualify: boolean): boolean => {
  return isNewPerformanceYear || noLongerQualify;
};

export default function BannerTile({
  ttciData,
  isNewPerformanceYear,
  period,
  country,
  locale,
  learnMoreLink,
  isLoading,
}: Readonly<Props>) {
  const { t } = useTranslation(["ttci"]);
  const { status, bonus, bonusCurrency, noLongerQualify, displayAnaVolTrackingWarning } = ttciData;
  const PY = `PY${getPerformanceYearShort(period)}`;
  const bonusAmount = status === TTCI_STATUS.NOT_TRACKING ? 0 : bonus;
  const currentBonus = formatCurrency(bonusAmount, bonusCurrency || getDefaultCurrencyCode(), locale, country, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const bannerMessageContent = getBannerMessage(ttciData, isNewPerformanceYear);
  const bannerSubTitleContent = getBannerSubtitle(ttciData, isNewPerformanceYear);

  return (
    <LeafTile isLoading={isLoading}>
      <div id="ttci-banner-tile" className="ttci-banner-container">
        <section className="ttci-banner-container__section-one">
          <div
            className={`ttci-banner-container__title${
              isNewPerformanceYear ? " ttci-banner-container__newyear-banner" : ""
            }`}
          >
            <Trans>
              {t(`bannerTile.${bannerMessageContent}`, defaultContent.bannerTile[bannerMessageContent], {
                currentPy: PY,
              })}
              <span className="bold-banner-text"></span>
            </Trans>
          </div>
          <br />
          <div>
            {t(`bannerTile.${bannerSubTitleContent}`, String(defaultContent.bannerTile[bannerSubTitleContent]))}
          </div>
          <br />
        </section>
        <section className="ttci-banner-container__section-two">
          <div className="banner-message-box" hidden={!displayAnaVolTrackingWarning}>
            <LeafMessageBox color={"orange"}>{t("vcsReqMessage", defaultContent["vcsReqMessage"])}</LeafMessageBox>
          </div>
          <div hidden={hidePotentialEarningsSection(isNewPerformanceYear, noLongerQualify)}>
            <LeafColorBlock>
              <div>
                {t("bannerTile.potentialEarnings", defaultContent.bannerTile["potentialEarnings"], {
                  currentPy: PY,
                })}
              </div>
              <div className="lower-content">{currentBonus}</div>
            </LeafColorBlock>
          </div>
          <div className="ttci-banner-container__btn" hidden={learnMoreLink === ""}>
            <LeafLearnMoreLink href={learnMoreLink} />
          </div>
        </section>
      </div>
    </LeafTile>
  );
}
