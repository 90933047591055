import "./VcsPercentRequirementTile.scss";
import { Trans, useTranslation } from "react-i18next";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import LeafTag from "../../../leaf/LeafTag/LeafTag";
import LeafHalfCircle from "../../../leaf/LeafProgressHalfCircle/LeafProgressHalfCircle";
import defaultContent from "../../../../content/vcsRequirementTile";

interface requirementProps {
  isLoading: boolean;
  additionalNeeded: number;
  targetValue: number;
  shouldShowGraphics: boolean;
  leftValue: number;
  rightValue: number;
  met: boolean;
  percent?: number;
  hideDescription?: boolean;
  hideBorder?: boolean;
  circleCheckmarkId?: string;
}

export const VcsPercentRequirementTile = ({
  isLoading,
  additionalNeeded,
  targetValue,
  shouldShowGraphics,
  leftValue,
  rightValue,
  met,
  percent,
  hideDescription = false,
  hideBorder = false,
  circleCheckmarkId = "",
}: requirementProps) => {
  const { t } = useTranslation(["vcsRequirementTile"]);
  const additionalNeededTrunc = additionalNeeded && additionalNeeded >= 0 ? Math.round(additionalNeeded) : 0;

  const getTitle = () => {
    return t("title", defaultContent["title"], { targetVcsPercent: targetValue });
  };

  const getDescription = () => {
    return t("description", defaultContent["description"], { targetVcsPercent: targetValue });
  };

  const getMetDescription = () => {
    return t("requirementMet", defaultContent["requirementMet"]);
  };

  const getTagContent = () => {
    return t("percentNeeded", defaultContent["percentNeeded"], {
      additionalPercentNeeded: additionalNeededTrunc,
    });
  };

  const ProgressTracker = () => {
    return (
      <div className="vcsRequirementTile__leafHalfCircle">
        <LeafHalfCircle
          current={additionalNeededTrunc}
          maxValue={100}
          target={targetValue}
          leftValue={Math.trunc(leftValue)}
          leftLabel={"vcs"}
          rightValue={Math.trunc(rightValue)}
          rightLabel={"ppv"}
          percent={percent}
          ariaTitle={getTitle()}
        ></LeafHalfCircle>
      </div>
    );
  };

  const renderTag = () => {
    return met === false ? (
      <LeafTag hideTag={!shouldShowGraphics} classname="vcsRequirementTile__tag" variant={met ? "green" : "orange"}>
        <Trans>
          {getTagContent()}
          <span className="vcsRequirementTile__tagContent"></span>
        </Trans>
      </LeafTag>
    ) : (
      <div className="vcsRequirementTile__tag"></div>
    );
  };

  const renderTileDetails = () => {
    if (additionalNeeded >= 0 && met) {
      <>
        <section className="vcsRequirementTile__description">{`${getMetDescription()}`}</section>
      </>;
    }
    return (
      <>
        <div className="vcsRequirementTile__content">
          <div className="vcsRequirementTile__progressTracker" hidden={!shouldShowGraphics}>
            <ProgressTracker />
          </div>

          {hideDescription === false && (
            <section className="vcsRequirementTile__description">{getDescription()}</section>
          )}
        </div>
        {renderTag()}
      </>
    );
  };

  return (
    <div className="vcsRequirementTile">
      <LeafTile
        title={getTitle()}
        met={met}
        showMetStatus={true}
        isLoading={isLoading}
        id={("vcsRequirementTile__baseTile__" + circleCheckmarkId).trim()}
        hideBorder={hideBorder}
        circleCheckmarkId={circleCheckmarkId.trim()}
      >
        {renderTileDetails()}
      </LeafTile>
    </div>
  );
};
