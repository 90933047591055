import { useState } from "react";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import LeafRadioMenu from "../../../../common/leaf/LeafRadioMenu/LeafRadioMenu";
import "./ServerSideExportMenu.scss";

type Option = {
  id: string;
  text: string;
};

type Props = {
  title: string;
  subTitle: string;
  options: Option[];
  exportButton: {
    text: string;
    onClick: (id: string) => void;
  };
  showExportButton?: boolean;
  viewExportsButton?: {
    text: string;
    onClick: () => void;
  };
  showViewExportsButton?: boolean;
  show?: boolean;
};

const ServerSideExportMenu = (props: Props) => {
  const {
    title,
    subTitle,
    options,
    exportButton,
    showExportButton = true,
    viewExportsButton,
    showViewExportsButton = false,
    show = true,
  } = props;

  const [selectedId, setSelectedId] = useState("");

  const requestExportDetails = () => {
    document.dispatchEvent(
      new CustomEvent("RequestExportDetails", {
        bubbles: true,
        composed: true,
        detail: {
          moduleId: "los",
          exportSelectedKPIS: "false",
          isFullMap: "false",
        },
      }),
    );
  };

  const setOnSelected = (id: string) => {
    requestExportDetails();
    setSelectedId(id);
  };

  if (show === false) {
    return <></>;
  }

  return (
    <div className="server-side-export-menu" data-testid="serverSideExportMenu">
      <div className="server-side-export-menu__header">
        <div className="server-side-export-menu__header__title">{title}</div>
        <div className="server-side-export-menu__header__subtitle">{subTitle}</div>
      </div>
      <div className="server-side-export-menu__content">
        <LeafRadioMenu options={options} onSelected={setOnSelected} selectedId={selectedId} />
      </div>
      <div className="server-side-export-menu__footer">
        {showExportButton && (
          <LeafFilterButton
            active={selectedId !== ""}
            text={exportButton.text}
            onButtonClick={() => exportButton.onClick(selectedId)}
            theme={"dark"}
          />
        )}
        {showViewExportsButton && (
          <LeafFilterButton
            text={viewExportsButton ? viewExportsButton.text : ""}
            onButtonClick={() => viewExportsButton?.onClick()}
          />
        )}
      </div>
    </div>
  );
};

export default ServerSideExportMenu;
