export enum TTCI_STATUS {
  TRACKING = "TRACKING",
  NOT_TRACKING = "NOT_TRACKING",
  NOT_QUALIFIED = "NOT_QUALIFIED",
  QUALIFIED = "QUALIFIED",
  HELD = "HELD",
  DENIED = "DENIED",
  ACHIEVED = "ACHIEVED",
  ELIGIBLE = "ELIGIBLE",
  PENDING_ELIGIBLE = "PENDING_ELIGIBLE",
  MANUALLY_APPROVED = "MANUALLY_APPROVED",
}
