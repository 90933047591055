import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeIncentives";
import { storedLocale } from "../../../impersonation/util";
import { getLegByLegNumber } from "../pathToBronzeUtils";
import { getFullMonth } from "../../../../common/util/period";
import { learnMoreOnClick } from "../../../../common/tealium/BronzeIncentive/PathToBronze/LearnMoreButtonTrack";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import "./PtbBannerTile.scss";
import { useAppSelector } from "../../../../store";
import { PathToBronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type Props = {
  data: PathToBronzeOverviewResponse;
  isLoading: boolean;
  selectedPeriod: string;
  extraBonusesMet: number;
};

export const PtbBannerTile = ({ data, isLoading, selectedPeriod, extraBonusesMet }: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const locale = storedLocale();
  const { bonus, legRequirements, personalVolume, perfBonusLevel } = data;
  const learnMoreLink = useAppSelector((store) => store.boot?.configuration?.dashboardLinks?.learnMore?.pathToBronze);
  const firstLegReq = getLegByLegNumber(legRequirements, 1);
  const maxBonus = getLegByLegNumber(legRequirements, legRequirements.length);
  const formattedBonusAmount = bonus.formatted;
  const maxBonusAvailable = maxBonus.bonusAmount.formatted;

  const __getAllReqsMetStatus = () => {
    if (firstLegReq && personalVolume && perfBonusLevel) {
      return (firstLegReq.met && personalVolume.met && (perfBonusLevel.met || !perfBonusLevel.required)) || false;
    }
    return false;
  };
  const allReqsMet = __getAllReqsMetStatus();

  // Derive status based on `data` fields
  const derivedStatus = (() => {
    if (data.denied) return QUALIFICATION_STATUS.DENIED;
    if (data.held) return QUALIFICATION_STATUS.HELD;
    if (data.manualApproval) return QUALIFICATION_STATUS.MANUALLY_QUALIFIED;
    if (data.earning && !extraBonusesMet) return QUALIFICATION_STATUS.QUALIFIED;
    if (data.earning && allReqsMet) return QUALIFICATION_STATUS.ELIGIBLE;
    if (data.graduated) return QUALIFICATION_STATUS.GRADUATED;
    if (data.ineligibleForPY || data.graduated || !data.eligible) return QUALIFICATION_STATUS.NOT_ELIGIBLE;
    return QUALIFICATION_STATUS.NOT_QUALIFIED;
  })();

  const bannerMessages = [
    {
      status: [QUALIFICATION_STATUS.QUALIFIED],
      title: `${t(
        "newAboActivation.earningIncentiveTitle",
        defaultContent["newAboActivation"]["earningIncentiveTitle"],
      )}`,
      subtitle: `${t(
        "newAboActivation.downlineGrowthDescription",
        defaultContent["newAboActivation"]["downlineGrowthDescription"],
      )}`,
      description: (
        <p className="PtbBannerTile__formatBonusAmount">
          <Trans>
            {`${t(
              "newAboActivation.bonusAmountDescription",
              defaultContent["newAboActivation"]["bonusAmountDescription"],
              {
                amount: maxBonusAvailable,
              },
            )}`}
            <span></span>
          </Trans>
        </p>
      ),
    },
    {
      status: [QUALIFICATION_STATUS.ELIGIBLE],
      title: `${t("newAboActivation.allReqsMet", defaultContent["newAboActivation"]["allReqsMet"])}`,
      subtitle: `${t(
        "newAboActivation.downlineGrowthDescription",
        defaultContent["newAboActivation"]["downlineGrowthDescription"],
      )}`,
    },
    {
      status: [QUALIFICATION_STATUS.NOT_QUALIFIED],
      title: `${t(
        "newAboActivation.notEarningIncentiveTitle",
        defaultContent["newAboActivation"]["notEarningIncentiveTitle"],
      )}`,
      subtitle: `${t(
        "newAboActivation.downlineGrowthDescription",
        defaultContent["newAboActivation"]["downlineGrowthDescription"],
      )}`,
      description: (
        <p className="PtbBannerTile__formatBonusAmount">
          <Trans>
            {`${t(
              "newAboActivation.bonusAmountDescription",
              defaultContent["newAboActivation"]["bonusAmountDescription"],
              {
                amount: maxBonusAvailable,
              },
            )}`}
            <span></span>
          </Trans>
        </p>
      ),
    },
    {
      status: [QUALIFICATION_STATUS.DENIED],
      title: `${t("newAboActivation.deniedIncentiveTitle", defaultContent["newAboActivation"]["deniedIncentiveTitle"], {
        month: getFullMonth(selectedPeriod, locale),
      })}`,
      subtitle: `${t(
        "newAboActivation.downlineGrowthDescription",
        defaultContent["newAboActivation"]["downlineGrowthDescription"],
      )}`,
      description: (
        <p className="PtbBannerTile__formatBonusAmount">
          <Trans>
            {`${t(
              "newAboActivation.bonusAmountDescription",
              defaultContent["newAboActivation"]["bonusAmountDescription"],
              {
                amount: maxBonusAvailable,
              },
            )}`}
            <span></span>
          </Trans>
        </p>
      ),
    },
    {
      status: [QUALIFICATION_STATUS.HELD],
      title: `${t("newAboActivation.HoldIncentiveTitle", defaultContent["newAboActivation"]["onHoldIncentiveTitle"])}`,
      subtitle: `${t(
        "newAboActivation.customerCareContactDescription",
        defaultContent["newAboActivation"]["customerCareContactDescription"],
      )}`,
    },
    {
      status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
      title: `${t(
        "newAboActivation.manuallyApprovedIncentiveTitle",
        defaultContent["newAboActivation"]["manuallyApprovedIncentiveTitle"],
      )}`,
      subtitle: `${t(
        "newAboActivation.customerCareContactDescription",
        defaultContent["newAboActivation"]["customerCareContactDescription"],
      )}`,
    },
    {
      status: [QUALIFICATION_STATUS.GRADUATED],
      title: `${t(
        "newAboActivation.eligibilityTile.graduatedTitle",
        defaultContent["newAboActivation"]["eligibilityTile"]["graduatedTitle"],
      )}`,
    },
    {
      status: [QUALIFICATION_STATUS.NOT_ELIGIBLE],
      title: `${t(
        "newAboActivation.eligibilityTile.ineligibleTitle",
        defaultContent["newAboActivation"]["eligibilityTile"]["ineligibleTitle"],
        {
          dateText: getBronzeDisplayDate(selectedPeriod),
        },
      )}`,
      subtitle: `${t(
        "newAboActivation.eligibilityTile.ineligibleDescription",
        defaultContent["newAboActivation"]["eligibilityTile"]["ineligibleDescription"],
        {
          achieveLimit: data.awardCap.target,
        },
      )}`,
    },
  ];

  const statusToHideIncentive = [
    QUALIFICATION_STATUS.DENIED,
    QUALIFICATION_STATUS.NOT_ELIGIBLE,
    QUALIFICATION_STATUS.HELD,
    QUALIFICATION_STATUS.GRADUATED,
  ];

  return (
    <LeafIncentiveBannerTile
      bannerMessages={bannerMessages}
      incentive={{
        title: `${t("ptbLabel", defaultContent["ptbLabel"])}`,
        status: derivedStatus,
        amount: formattedBonusAmount,
        statusToHideIncentive,
      }}
      learnMoreLink={learnMoreLink}
      isLoading={isLoading}
      isCompactView={true}
      learnMoreTealiumFunction={learnMoreOnClick}
    />
  );
};
