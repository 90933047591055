const content: { [key: string]: any } = {
  exportFields: {
    aboNo: "ABO Number",
    level: "ABO Level",
    sponsorABONo: "Sponsor ABO Number",
    countryCode: "Country",
    name: "Name",
    registrationDate: "Entry Date",
    phone: "Phone",
    email: "Email",
    address: "Address",
    bonusPercent: "Bonus Percent",
    personalPV: "PPV",
    groupPV: "GPV",
    groupBV: "GBV",
    totalABOCount: "Total ABOs",
    totalNewABOCount: "New ABOs",
    personalOrdersCount: "Personal Order Count",
    groupOrdersCount: "Group Orders Count",
    pointsToNextLevel: "Points to Next level",
    totalCustomerCount: "Total Customers",
    nonVCSPV: "Non VCS PV",
    customerPV: "Customer PV",
    vcsPV: "VCS PV",
    personalVcsPercent: "VCS %",
    cbrStartMonth: "Starting Month",
    cbrEndMonth: "Ending Month",
    cbrPeriodRemaining: "Remaining Months",
    cbrVcsPercent: "Current Month VCS %",
    birthdate: "Birthday",
    sponsorName: "Sponsor Name",
    renewalDate: "Renewal Date",
    isFirstContractYear: "First Contract Year",
    autoRenewal: "Auto Renewal",
    rubyPV: "Ruby PV",
    qualifiedLegs: "Qualified Legs",
  },
  textOverride: {
    headers: {
      company: "Amway",
      bonusPeriod: "Bonus Period",
      disclaimer:
        "CONFIDENTIAL - THIS REPORT CONTAINS AMWAY'S TRADE SECRETS, INCLUDING ITS LOS INFORMATION, CONFIDENTIAL AND PROPRIETARY BUSINESS INFORMATION. THE RECIPIENT/VIEWER AGREES TO MAINTAIN IT IN STRICTEST CONFIDENCE AND TO USE IT ONLY AS PERMITTED BY CONTRACT WITH AMWAY.",
    },
    phoneType: {
      primary: "Primary",
      secondary: "Secondary",
      home: "Home",
      alternate: "Alternate",
      mobile: "Mobile",
      business: "Business",
      businessFax: "Business Fax",
    },
    name: {
      confidentialUser: "Confidential User",
    },
    cbrPeriodRemaining: {
      closingMonth: "Closed",
    },
    autoRenewal: {
      optIn: "Opt In",
      optOut: "Opt Out",
    },
  },
  exportTypeModal: {
    header: "Select the content to export",
    subheader: "Export is available as a XLS/CSV/PDF file",
    menu: {
      los: {
        all: "Entire Line of Sponsorship with all available KPIs",
        selectedKPIs: "Entire Line of Sponsorship with Selected KPIs",
        currentView: "Current View",
      },
    },
    exportButton: "Export",
    viewExportButton: "View Exports",
  },
  availableExportModal: {
    header: "Available Exports For Download",
    subheader: "Export is available as a XLS/CSV/PDF file",
    exportFile: {
      period: "Period:",
      aboDetail: "ABO #{aboNo} | {country}",
      startTime: "Started:",
      exportError: "Export Failed, please retry again.",
      inProgressMessage: "In Progress...",
      doneBoldMessage: "Ready",
      doneExpiresInMessage: "Expires in",
      lessThan: "less than",
      doneHourMessage: "Hour",
      doneHoursMessage: "Hours",
    },
    exportAnotherButton: "Export Another",
    noExportsAvailable: "You currently have no files ready for export.",
    deleteLabel: "Delete export",
    csvLabel: "Download export in CSV format",
    excelLabel: "Download export in Excel format",
    pdfLabel: "Download export in PDF format",
  },
  notifications: {
    exportInProgress:
      "Export is in progress. An on-screen notification will appear when your file is ready to download.",
    successMessage: "Your export is now ready to download.",
    failureMessage: "Your export request has failed",
    excelLink: "Download XLS",
    csvLink: "Download CSV",
    pdfLink: "Download PDF",
  },
};

export default content;
