import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amwaycommon/acc-mixins/l10n";
import { useEffect, useRef } from "react";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import commonContent from "../../../../content/common";
import defaultContent from "../../../../content/ttci";
import "./EligibilityPin.scss";
import { AwardLevels, TTCIResponse } from "../../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import LeafTag, { TagColor } from "../../../../common/leaf/LeafTag/LeafTag";
import { TTCI_STATUS } from "../../../../common/enums/ttci-enums";
import { getAwardLabelKeyFromAwardCode } from "../../../../common/util/awardLabels";
import { getDefaultCurrencyCode } from "../../../../common/util/config";
import useWindowSize from "../../../../common/hooks/useWindowSize";

type Props = {
  ttciData: TTCIResponse;
  locale: string;
  country: string;
  isLoading: boolean;
};

export const EligibilityPin = ({ ttciData, locale, country, isLoading }: Props) => {
  const { t } = useTranslation(["ttci", "common"]);
  const { bonusCurrency } = ttciData;
  const rightTagRef = useRef<HTMLSpanElement>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (rightTagRef && rightTagRef.current) {
      rightTagRef.current.style.width = "auto";
      if (Number(rightTagRef.current.clientHeight.toString()) > 30) {
        rightTagRef.current.style.width = "min-content";
      }
    }
  }, [rightTagRef, width]);

  const _getLeftLeafTag = (pin: AwardLevels) => {
    let contentString = "";
    let colorVariant: TagColor = "blue";
    if (
      pin.status === TTCI_STATUS.TRACKING ||
      pin.status === TTCI_STATUS.ELIGIBLE ||
      pin.status === TTCI_STATUS.PENDING_ELIGIBLE
    ) {
      contentString = "tracking";
      colorVariant = "blue";
    }
    if (pin.achieved) {
      contentString = "achieved";
      colorVariant = "green";
    }
    if (pin.denied) {
      contentString = "denied";
      colorVariant = "red";
    }
    if (pin.held) {
      contentString = "onHold";
      colorVariant = "blue";
    }

    return contentString !== "" ? (
      <LeafTag classname="EligibilityPin__tag--left" variant={colorVariant}>{`${t(
        contentString,
        defaultContent[contentString],
      )}`}</LeafTag>
    ) : (
      <></>
    );
  };

  const _getRightLeafTag = (pin: AwardLevels) => {
    let contentString = pin.firstTime ? "firstYear" : "requalification";

    if (pin.a70CurrentYear) {
      switch (pin.a70CurrentYear) {
        case "first":
        case "second":
          contentString = "firstPayment";
          break;
        case "third":
          contentString = "secondPayment";
          break;
        default:
          return "";
      }
    }

    return (
      <LeafTag classname="EligibilityPin__tag--right" variant={"gray"}>{`${t(
        contentString,
        defaultContent[contentString],
      )}`}</LeafTag>
    );
  };

  return (
    <>
      {ttciData && ttciData.awardLevels ? (
        ttciData.awardLevels.map((pin, index) => {
          return (
            <div key={index} className="EligibilityPin__pinTile">
              <LeafTile isLoading={isLoading}>
                <div className="EligibilityPin__tagsSection">
                  <span className="EligibilityPin__tagLeft">{_getLeftLeafTag(pin)}</span>
                  <span className="EligibilityPin__tagRight" ref={rightTagRef}>
                    {_getRightLeafTag(pin)}
                  </span>
                </div>
                <div className="EligibilityPin__pinLabel">
                  {`${t(
                    `common:awardLabels.${getAwardLabelKeyFromAwardCode(pin.awardCode, pin.awardLevel)}`,
                    commonContent.awardLabels[getAwardLabelKeyFromAwardCode(pin.awardCode, pin.awardLevel)],
                  )}`}
                </div>
                <div className="EligibilityPin__amount">
                  {pin.displayBonusAmount &&
                    formatCurrency(pin.bonus, bonusCurrency || getDefaultCurrencyCode(), locale, country, {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}
                </div>
              </LeafTile>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
